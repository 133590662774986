@import "../style/colors";
@import "../style/layout";
@import "../style/typography";

body {
	background-color: $body-background;
	font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
	overflow-x: hidden !important;
}

.click {
	cursor: pointer;
}

.files {
	padding: 0 ($margin-step / 2);
	@extend .small-400;
}

.file-input-container {
	height: 40px;
	justify-content: flex-start;
	align-items: center;
	padding: 0 ($margin-step / 2);
	margin-top: 5px;
	display: inline-flex;

	.button {
		cursor: pointer;
		margin-right: $margin-step / 2;
		padding: ($margin-step / 4) ($margin-step / 2);
		border-radius: 2px;
		color: white;
		background-color: $color-action;
		@extend .small-500;
		width: max-content;
	}

	.placeholder {
		@extend .small-400;
		color: $color-primary;
		display: inline-block;
		text-overflow: ellipsis;
		width: auto;
		white-space: nowrap;
		overflow: hidden;
		vertical-align: middle;
		max-width: 400px;
	}
}

audio {
	width: -webkit-fill-available;
}

.hidden {
	display: none;
}

.file-name {
	font-size: 16px;
	font-weight: 700;
}

.section-upload + .section-upload {
	display: -webkit-box;
	margin-top: $margin-step * 2;
}

.form-column {
	> .champ + .champ {
		margin-top: $margin-step * 2;
	}
}

ul h3,
h3 {
	@extend .h3;
}

ul .section-title h3 {
	@extend .h3;
	color: $color-primary;
	margin-bottom: $margin-step * 2;

	&.with-description {
		margin-bottom: $margin-step;
	}
}

.checkbox.release-date {
	margin-bottom: $margin-step / 2;
}

.section {
	background-color: $row-background;
	border-radius: 5px;
	min-height: 450px;
	margin: 15px;
}

.section-divider {
	margin: ($margin-step * 4) 0;
	height: 1px;
	width: 100%;
	background-color: $color-stroke;
}

.description {
	margin-top: $margin-step;

	@extend .medium-400;
	color: $color-secondary;

	a {
		@extend .medium-500;
		color: $color-action;
	}
}

.space {
	margin-bottom: 100px;
}

.search input {
	width: auto !important;
	margin: 0px 0 0px 0 !important;
}

.audio {
	margin-top: 20px;
}

#Tooltip {
	display: inline;
	color: #687a8b;
	font-size: 10pt;
}

.isrc {
	margin: 0px 3px 3px 0px;
}

article {
	padding: 20px;
}

.section-about,
.section-info,
.section-disclaimer,
.section-articles {
	min-height: auto;
	padding: 1em;
}

.card {
	background: none !important;
	border: none !important;
}
.card-body {
	padding: 0 !important;
}
.navigation {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}
@media (min-width: 280px) and (max-width: 1300px) {
	.navigation {
		display: grid !important;
	}
	.column.img {
		width: 100% !important;
	}
	/*.img-wrapper {
		justify-content: inherit !important;
	}*/
	.img-wrapper.second {
		width: 50%;
	}
	.notsure-img,
	.memes-img,
	.memes-faces-img,
	.escalated-img,
	.article-img {
		width: 100% !important;
	}
	/*.credits {
		text-align: left !important;
	}*/
	.article-img.phonograph {
		height: auto !important;
	}
	.img-wrapper.second {
		margin: auto;
		display: block;
	}
}

.articles-buttons {
	display: grid;
}
.about-button,
.disclaimer-button,
.info-button,
.copyright-button,
.creators-button,
.memes-button {
	background-color: #a3c4fd !important;
	border: none !important;
	color: #212529 !important;
	margin: 1em;
	padding: 1em;
	border-radius: 0.5em;
}
.creators-link,
.creators-link:hover {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	color: #007bff;
	outline: none !important;
	box-shadow: none !important;
}
.copyright-button,
.creators-button,
.memes-button {
	text-align: left;
}
.img-wrapper {
	max-width: 50%;
	margin: auto;
}
.img-wrapper,
.phonograph-wrapper,
.copies-wrapper,
.summary-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}
.column.img {
	float: left;
	padding: 5px;
}
.column.img.first {
	width: 25%;
}
.column.img.second {
	width: 50%;
}
.row.img {
	align-items: center !important;
}
.row.img::after {
	content: "";
	clear: both;
	display: table;
}
.article-img {
	width: 100%;
	height: 100%;
}
.summary-img,
.article-img.youtube,
.article-img.claim {
	width: 50%;
}
.copies-img {
	width: 30%;
}
.c-logo {
	width: 100px;
	height: 50px;
	float: left;
}
.cc-logo {
	width: 55px;
	height: 55px;
	float: left;
	margin: 0 30px 0 20px;
}
.smaller-font,
.credits,
.credits-bis {
	font-size: 12px;
}
.credits {
	text-align: center;
}
.notsure-img {
	width: 50%;
}
.imgs {
	width: 50%;
	display: block;
	margin: 0 auto;
}
.typewriter-img, 
.book-img {
	width: 30%;
}
.img-col {
	width: 100%;
}
@media (max-width: 768px) {
	.row {
		display: block;
	}
	.credits-bis {
		text-align: center;
	}
}
.flex{
  display: flex;
  justify-content: center;
}
.ad{
	display: block;
}