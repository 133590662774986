$color-action: #2DA84F;

$color-primary: #203548;
$color-primary-reverse: #FFFFFF;
$color-secondary: #687A8B;
$color-tertiary: #8DA0B2;
$color-stroke: #DCDFE1;

$color-orange-dark: #F2724A;
$color-orange: #FFD0A9;
$color-green: #C6F3B6;

$color-underground: #FAF8F9;
$color-hell: #F2EFF0;

$body-background: #d1e0ff;
$row-background: #a3c4fd;
  

.color-primary {
    color: $color-primary;
}

.color-secondary {
    color: $color-secondary;
}

.color-tertiary {
    color: $color-tertiary;
}
